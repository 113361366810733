export default [
  {
    title: 'Ana Sayfa',
    route: 'customer-home',
    icon: 'HomeIcon',
  },
  {
    title: 'Müşteri Bilgi',
    route: 'customer-musteri',
    icon: 'UserIcon',
  },

  // Tekliflerim
  {
    title: 'Tekliflerim',
    route: 'customer-teklif',
    icon: 'BookIcon',
  },

  // Siparis
  {
    title: 'Siparişlerim',
    route: 'customer-siparis',
    icon: 'GridIcon',
  },

  // Cari Ekstra
  {
    title: 'Cari Ekstre',
    icon: 'FileTextIcon',
    route: 'customer-cariekstra',
  },

  // Lisans Sifre Bilgi
  {
    title: 'Lisanslar',
    icon: 'KeyIcon',
    route: 'customer-lisanssifrebilgi',
  },
  
  // Cloud Kullanıcı
  {
    title: 'Cloud Kullanıcılar',
    icon: 'CloudIcon',
    children: [
      {
        title: 'WebApi Kullanıcıları',
        route: 'customer-webapi-kullanici',
      },
      {
        title: 'Veri Tabanı Kullanıcıları',
        route: 'customer-db-kullanici',
      },
    ],
  },

  // Destek 
  {
    title: 'Destek',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Yeni Destek Oluştur',
        route: 'customer-support-new',
      },
      {
        title: 'Destek Kayıtlarım',
        route: 'customer-support-records',
      },
      {
        route: 'customer-support-detail',
        hidden: true,
      },
    ],
  },
  
  // Odemeler
  {
    title: 'Ödeme',
    icon: 'CreditCardIcon',
    route: {
      name: 'customer-payment',
      params: { status: 'pay' },
    },
  },
]
