export default [
  {
    title: 'Ana Sayfa',
    route: 'manage-home',
    icon: 'HomeIcon',
  },

  /* Crm */ 
  {
    header: 'Crm',
  },
  {
    title: 'Müşteri Yönetim',
    icon: 'UserIcon',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'manage-kullanici',
      },
      {
        title: 'Müşteriler',
        route: 'manage-musteri',
      },

      {
        title: 'Müşteri Detay',
        route: 'manage-musteri-save',
        hidden: true,
      },
      {
        title: 'Kullanıcı Detay',
        route: 'manage-kullanici-save',
        hidden: true,
      },
    ],
  },

  // urun
  {
    title: 'Ürün Yönetim',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Tüm Ürünler',
        route: 'manage-urun',
      },

      {
        title: 'Ürün Düzenleme',
        route: 'manage-urun-detay',
        hidden: true,
      },
    ],
  },

  // Teklif
  {
    title: 'Teklif Yönetim',
    icon: 'BookIcon',
    children: [
      {
        title: 'Teklifler',
        route: 'manage-teklif',
      },
      {
        title: 'Kapanan Teklifler',
        route: 'manage-teklif-kapanan',
      },

      {
        title: 'Teklif Detay İşlemleri',
        route: 'management-teklif-islem',
        hidden: true,
      },
    ],
  },

  // Siparis
  {
    title: 'Sipariş Yönetim',
    icon: 'GridIcon',
    children: [
      {
        title: 'Tüm Sipariş',
        route: 'manage-siparis',
      },

      {
        title: 'Arşiv',
        route: 'manage-siparis-arsiv',
      },

      {
        title: 'Sipariş Detay',
        route: 'manage-siparis-detay',
        hidden: true,
      },

      {
        title: 'Sipariş Arşiv Detay',
        route: 'manage-siparis-arsiv-detay',
        hidden: true,
      },
    ],
  },

  // Cari Ekstra
  {
    title: 'Cari Ekstre',
    icon: 'FileTextIcon',
    route: 'manage-cariekstra',
  },

  // Ödemeler
  {
    title: 'Ödemeler',
    icon: 'CreditCardIcon',
    route: 'manage-payments',
  },

  /* Cloud */ 
  {
    header: 'Cloud',
  },
  // Lisans Sifre Bilgi
  {
    title: 'Lisans Yönetim',
    icon: 'KeyIcon',
    route: 'manage-lisanssifrebilgi',
  },
  // DBFirmaInfo
  {
    title: 'Firma Veritabanı',
    icon: 'DatabaseIcon',
    route: 'manage-dbfirmainfo',
  },

  // AppAuthForm
  {
    title: 'Loglar',
    icon: 'ListIcon',
    route: 'manage-logs',
  },

  // Cloud Kullanıcı
  {
    title: 'Cloud Kullanıcılar',
    icon: 'CloudIcon',
    children: [
      {
        title: 'WebApi Kullanıcıları',
        route: 'manage-webapi-kullanici',
      },
      {
        title: 'Veri Tabanı Kullanıcıları',
        route: 'manage-db-kullanici',
      },
    ],
  },
  
  /* Hizmet */ 
  {
    header: 'Hizmet',
  },
  // Duyuru
  {
    title: 'Duyuru Yönetim',
    icon: 'Volume2Icon',
    route: 'manage-duyuru',
  },

  // Destek
  {
    title: 'Destek Yönetim',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Açık Talepler',
        route: 'manage-support-open',
      },
      {
        title: 'Kapanan Talepler',
        route: 'manage-support-closed',
      },
      {
        route: 'manage-support-detail-close',
        hidden: true,
      },
      {
        route: 'manage-support-detail-open',
        hidden: true,
      },
    ],
  },

  // Destek
  {
    title: 'Taleb Yönetim',
    icon: 'LifeBuoyIcon',
    children: [
      {
        title: 'Demo Talepler',
        route: 'demo-taleb',
      },
      {
        title: 'Bayı Talepler',
        route: 'bayi-taleb',
      },
    ],
  },

  /* Ayarlar */ 
  {
    header: 'Ayar',
  },
  // Tanim
  {
    title: 'Tanım Yönetim',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Genel Ayarlar',
        route: 'manage-genelayar',
      },
      {
        title: 'Sözleşmeler',
        route: 'manage-sozlesme',
      },
      {
        title: 'Vergi Daire Listesi',
        route: 'manage-vergidaire',
      },
      {
        title: 'Adresler',
        route: 'manage-adres',
      },
      {
        title: 'Banka Bilgi Listesi',
        route: 'manage-bankabilgi',
      },
      {
        title: 'Ürün Grup Listesi',
        route: 'manage-urungrup',
      },
      {
        title: 'Teklif Açıklama Listesi',
        route: 'manage-teklifaciklama',
      },
      {
        title: 'Satış Personel Listesi',
        route: 'manage-satispersonel',
      },

      {
        title: 'Vergi Daire Ekleme',
        route: 'manage-vergidaire-create',
        hidden: true,
      },
      {
        title: 'Adres Ekleme',
        route: 'manage-adres-create',
        hidden: true,
      },

      {
        title: 'Ürün Grup Düzenleme',
        route: 'manage-urungrup-save',
        hidden: true,
      },

      {
        title: 'Satış Personel Düzenleme',
        route: 'manage-satispersonel-save',
        hidden: true,
      },

    ],
  },
  
  // Website
  {
    title: 'Website Yönetim',
    icon: 'UserIcon',
    children: [
      {
        title: 'Genel',
        route: 'manage-website-genel',
      },
      {
        title: 'Hakkımızda',
        route: 'manage-website-aboutus',
      },
      {
        title: 'Referanslar',
        route: 'manage-website-referans',
      },
      {
        title: 'Çözüm Ortakları',
        route: 'manage-website-cozumortak',
      },
      {
        title: 'Banner',
        route: 'manage-website-banner',
      },
      {
        title: 'Sayfa',
        route: 'manage-website-sayfa',
      },
      {
        title: 'İletişim',
        route: 'manage-website-address',
      },
      {
        title: 'İletişim Formları',
        route: 'manage-website-contact-form',
      },

      {
        title: 'Referans Düzenleme',
        route: 'manage-website-referans-save',
        hidden: true,
      },

      {
        title: 'Çözüm Ortak Düzenleme',
        route: 'manage-website-cozumortak-save',
        hidden: true,
      },

      {
        title: 'Banner Düzenleme',
        route: 'manage-website-banner-save',
        hidden: true,
      },

      {
        title: 'Sayfa Düzenleme',
        route: 'manage-website-sayfa-save',
        hidden: true,
      },
    ],
  },

  {
    title: 'Uygulama Yönetim',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Versiyon Kontrol',
        route: 'manage-app-version',
      },
    ],
  },

  /* Kalite */ 
  {
    header: 'Kalite',
  },
  {
    title: 'Kalite Yönetim',
    icon: 'FilterIcon',
    children: [
      {
        title: 'Beton Dayanım Sınıfları',
        route: 'manage-beton-dayanim-sinif-list',
      },
      {
        title: 'Çevresel Etkiler',
        route: 'manage-cevresel-etki-list',
      },
      {
        title: 'DMAX Oranları',
        route: 'manage-dmax-oran-list',
      },
      {
        title: 'Klor Oranları',
        route: 'manage-klor-oran-list',
      },
      {
        title: 'Kırma Planları',
        route: 'manage-kirma-plan-list',
      },
      {
        title: 'Numune Şekli',
        route: 'manage-numune-sekli-list',
      },
      {
        title: 'Yapı Elemanı',
        route: 'manage-yapi-elemani-list',
      },
    ],
  },
]
