import { getUserRole, Role } from '@/auth/utils'
import management from './management'
import customer from './customer'

export default () => {
  // Array of sections
  if (getUserRole() === Role.SuperAdmin || getUserRole() === Role.SuperOperator) {
    return [...management]
  }

  if (getUserRole() === Role.MusteriAdmin || getUserRole() === Role.MusteriOperator) {
    return [...customer]
  }

  return [];
};
